import React, {Component} from 'react';
import './SimpleClock.css';

class SimpleClock extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date()}
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return (
            <div>
                <h1>Klockan är: {this.state.date.toLocaleTimeString()}</h1>
            </div>
        );
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }
}

export default SimpleClock;