import React, {Component, Fragment} from 'react';
import posed from 'react-pose';
import SplitText from 'react-pose-text';
import {tween} from 'popmotion';
import {interpolate} from 'flubber';
import './SimpleClock';
import logo from './logo.svg';
import './App.css';
import SimpleClock from "./SimpleClock";

//Animation
const charPoses = {
    exit: {y: 20, opacity: 0},
    enter: {
        y: 0,
        opacity: 1,
        transition: ({charInWordIndex}) => ({
            type: 'spring',
            delay: charInWordIndex * 30,
            stiffness: 500 + charInWordIndex * 150,
            damping: 10 - charInWordIndex * 1
        })
    }
};

const paths = {
    plane:
        'M510,255c0-20.4-17.85-38.25-38.25-38.25H331.5L204,12.75h-51l63.75,204H76.5l-38.25-51H0L25.5,255L0,344.25h38.25 l38.25-51h140.25l-63.75,204h51l127.5-204h140.25C492.15,293.25,510,275.4,510,255z',
    circle:
        'M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z',
    heart:
        'M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55 C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z',
    bookmark:
        'M357,0H102C73.95,0,51,22.95,51,51v408l178.5-76.5L408,459V51C408,22.95,385.05,0,357,0z',
};

const pathIds = Object.keys(paths);

const morphTransition = ({from, to}) =>
    tween({
        from: 0,
        to: 1
    }).pipe(interpolate(from, to));

const Icon = posed.path(
    pathIds.reduce((config, id) => {
        config[id] = {
            d: paths[id],
            transition: morphTransition
        };

        return config;
    }, {})
);

class App extends Component {

    state = {pathIndex: 0};

    gotoNext = () => {
        const {pathIndex} = this.state;
        const nextIndex = pathIndex + 1;
        this.setState({
            pathIndex: nextIndex > pathIds.length - 1 ? 0 : nextIndex
        });
    };

    componentDidMount() {
        this.timerID = setInterval(
            () => this.gotoNext(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <h1 className="App-title">{process.env.REACT_APP_TITLE}</h1>
                </header>
                <div className="App-intro">
                    <h1>
                        <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
                            Familjen Holmberg is moving to ReactJS, stay tuned...
                        </SplitText>
                    </h1>
                </div>
                <div className="clock">
                    <SimpleClock/>
                </div>
                <Fragment>
                    <svg width="400" height="400" viewBox="0 0 520 500">
                        <Icon pose={pathIds[this.state.pathIndex]}/>
                    </svg>
                </Fragment>
            </div>
        );
    }
}

export default App;
